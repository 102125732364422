import { Icon } from '@iconify/react';
// material
import { Box, Card, CardHeader, Divider, ListItemIcon, ListItemText, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { PhoneNumber } from '@nvapps/common/components/ui/PhoneInput';
import { useSessionStorage } from '@nvapps/common/hooks';
import { canRenewDevice } from '@nvtracker/common/devices';
import { ContractStatusEnum, getStatusOfContract } from '@nvtracker/common/helpers';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getDevicePosition } from '../../api/devices';
import RenewalDeviceDialog from '../../modals/devices/RenewalDeviceDialog';
import { ViewUserAndDeviceDialog } from '../../modals/devices/ViewDeviceDialog';
import { useDevicesExpiring, useDevicesExpiringExport } from '../../store/devices';
import BlinkIcon from '../Ui/BlinkIcon';
import DateTime from '../Ui/DateTime';
import { GsmState, GsmStateMenuItem } from '../Ui/GsmState';
import MoreMenu from '../Ui/MoreMenu';

const expiringSelectOptions = [
    {
        value: 30,
        text: 'in30days'
    },
    {
        value: 60,
        text: 'in60days'
    },
    {
        value: 90,
        text: 'in90days'
    },
    {
        value: -1,
        text: 'expired_plural'
    }]


function ContractCell({ renewalDate, hasPendingPurchase }) {
    const { t } = useTranslation();;
    const translation = getStatusOfContract(renewalDate) === ContractStatusEnum.EXPIRED ? 'expired' : 'expires';
    const text = renewalDate ? t(`dates.${translation}`, { date: new Date(renewalDate) }) : '-';

    return <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="subtitle2" noWrap>
            {text}
        </Typography>
        <Box display="flex" flexDirection="row" gap={0.5}>
            <Typography variant="caption" noWrap >
                <DateTime datetime={renewalDate} format='P' />
            </Typography>
            {hasPendingPurchase && <Tooltip title={<Trans>pendingPurchase</Trans>}>
                <Box color="warning.dark">
                    <BlinkIcon icon="ant-design:warning-outlined" style={{ marginTop: -2 }} height={18} width={18} />
                </Box>
            </Tooltip>}
        </Box>
    </Box>
}

const initialRenewalDeviceProps = { open: false, device: null };
const initialViewDeviceProps = { open: false, user: null, device: null };

export default function DevicesExpiring() {

    const { t } = useTranslation();
    const [filters, setFilters] = useSessionStorage('devicesExpiringFilters.v1', { daysMargin: 30, sortModel: [{ field: 'renewalDate', sort: 'asc' }] });
    const [devices, isLoading] = useDevicesExpiring(filters.daysMargin, filters.onlyExpired);
    const [renewalDeviceDialog, setRenewalDeviceDialog] = useState(initialRenewalDeviceProps);
    const [viewDeviceDialog, setViewDeviceDialog] = useState(initialViewDeviceProps);
    const [exportList, isExporting] = useDevicesExpiringExport();
    const [positionLoading, setPositionLoading] = useState(false);

    const handleRenewed = ({ planId, renewalDate }) => {
        const device = devices.find(e => e.id === viewDeviceDialog.device.id);
        if (device && viewDeviceDialog.open) setViewDeviceDialog({ ...viewDeviceDialog, device: { ...viewDeviceDialog.device, renewalDate, planId } });
    }

    const handleOnChangeSmsState = ({ gsmNumberActive, gsmNumberStateDate, gsmNumberStateUsername }) => {
        const device = devices.find(e => e.id === viewDeviceDialog.device.id);
        if (device && viewDeviceDialog.open) setViewDeviceDialog({ ...viewDeviceDialog, device: { ...viewDeviceDialog.device, gsmNumberActive, gsmNumberStateDate, gsmNumberStateUsername } });
    }

    const getUserAndDeviceOfRow = row =>
    ({
        user:
        {
            userName: row.userName,
            name: row.userRealName,
            phoneNumber: row.userPhoneNumber
        },
        device:
        {
            id: row.id,
            userId: row.userId,
            name: row.name,
            brand: row.brand,
            model: row.model,
            gsmNumber: row.gsmNumber,
            gsmNumberActive: row.gsmNumberActive,
            gsmNumberStateDate: row.gsmNumberStateDate,
            gsmNumberStateUsername: row.gsmNumberStateUsername,
            smsCredits: row.smsCredits,
            smsLimit: row.smsLimit,
            smsDate: row.smsDate,
            serialNumber: row.serialNumber,
            renewalDate: row.renewalDate,
            planId: row.planId,
            equipmentVersion: row.equipmentVersion,
            hasPendingPurchase: row.hasPendingPurchase,
            status: row.status,
            deviceTypeId: row.deviceTypeId,
            position: row.position,
            lastResult: row.lastResult
        }
    });

    const handleViewDeviceDialog = row => {
        if (!row.id) return;

        setPositionLoading(true);
        getDevicePosition(row.id).then(res => {
            const data = getUserAndDeviceOfRow({ ...row, position: res.data });
            setViewDeviceDialog({ open: true, ...data });
        }).finally(r => {
            setPositionLoading(false);
        });
    }

    const columns =
        [{
            field: 'name',
            headerName: t('name'),
            flex: 0.2,
            minWidth: 150,
            renderCell: ({ row: { name, brand, model } }) =>
                <Box display="flex" flexDirection="column" ml={1}>
                    <Typography variant="subtitle2" noWrap>
                        {`${name}`}
                    </Typography>
                    <Typography variant="caption" noWrap >
                        {[brand, model].filter(e => e).join(' ')}
                    </Typography>
                </Box>
        },
        {
            field: 'userName',
            headerName: t('client'),
            flex: 0.2,
            minWidth: 150,
            renderCell: ({ row: { userName, userRealName, userContact } }) =>
                <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2" noWrap>
                        {`${userRealName || userName}`}
                    </Typography>
                    {userRealName &&
                        <Typography variant="caption" noWrap >
                            {userName}
                        </Typography>}
                    {userContact && <Typography variant="caption" pt={0.5} noWrap>
                        <PhoneNumber value={userContact} />
                    </Typography>}
                </Box>
        },
        {
            field: 'serialNumber',
            headerName: t('info'),
            minWidth: 150,
            flex: 0.2,
            renderCell: ({ row: { serialNumber, gsmNumber, smNumber, gsmNumberActive, gsmNumberStateDate, gsmNumberStateUsername } }) =>
                <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2" noWrap>
                        {serialNumber}
                    </Typography>
                    <GsmState gsmNumber={gsmNumber} gsmNumberActive={gsmNumberActive} gsmNumberStateDate={gsmNumberStateDate} gsmNumberStateUsername={gsmNumberStateUsername} typographyProps={{ variant: "subtitle2", noWrap: true }} pt={0.5} />
                    {/* <Typography variant="caption" noWrap pt={0.5}>
                        <PhoneNumber value={gsmNumber} />
                    </Typography> */}
                </Box>
        },
        {
            field: 'renewalDate',
            headerName: t('contractValidity'),
            minWidth: 200,
            flex: 0.2,
            renderCell: ({ row }) => <ContractCell renewalDate={row.renewalDate} hasPendingPurchase={row.hasPendingPurchase} />
        },
        {
            field: 'options',
            sortable: false,
            renderHeader: _ => <div></div>,
            align: 'right',
            width: 70,
            renderCell: ({ row }) => <MoreMenu width={250}>
                <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleViewDeviceDialog(row)} >
                    <ListItemIcon>
                        <Icon icon="eva:search-outline" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary={t('view')} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                {canRenewDevice(row) &&
                    <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setRenewalDeviceDialog({ open: true, ...getUserAndDeviceOfRow(row) })} >
                        <ListItemIcon>
                            <Icon icon="ant-design:calendar-outlined" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary={t('renew')} primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>}
                <GsmStateMenuItem deviceId={row.id} gsmNumberActive={row.gsmNumberActive} />
                {row.userId && <Divider />}
                {row.userId &&
                    <MenuItem sx={{ color: 'text.secondary' }} component={NavLink} to={`/app/clients/${row.userId}/general`}>
                        <ListItemIcon>
                            <Icon icon="eva:person-fill" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary={t('clientRecord')} primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>}
            </MoreMenu>
        }];

    const handleFilterOptions = v => {
        setFilters({ ...filters, daysMargin: v === 'expired' ? null : v, onlyExpired: v === 'expired' })
    }

    return (
        <Card>
            <CardHeader
                title={t('expiringDevices')}
                action={
                    <>
                        <TextField
                            value={filters.daysMargin}
                            select
                            onChange={ev => handleFilterOptions(ev.target.value)}
                            sx={{ borderRadius: '6px', backgroundColor: 'rgb(244, 246, 248)', mr: 2 }}
                            SelectProps={{ sx: { fontSize: '0.85rem', fontWeight: 600 } }}
                            color="secondary"
                            size="small">
                            {expiringSelectOptions.map(e => <MenuItem key={e.value} value={e.value}>
                                {t(e.text)}
                            </MenuItem>)}
                        </TextField>
                        <MoreMenu>
                            <MenuItem sx={{ color: 'text.secondary' }} onClick={() => exportList(filters.daysMargin, filters.onlyExpired)} >
                                <ListItemIcon>
                                    <Icon icon="ic:round-print" width={24} height={24} />
                                </ListItemIcon>
                                <ListItemText primary={t('export')} primaryTypographyProps={{ variant: 'body2' }} />
                            </MenuItem>
                        </MoreMenu>
                    </>
                } />
            <Box sx={{ height: 400, px: 0 }}>
                <DataGrid
                    columns={columns}
                    rows={devices || []}
                    loading={isLoading || isExporting || positionLoading}
                    density="comfortable"
                    pagination
                    getRowId={d => d.id}
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    onRowClick={({ row }) => handleViewDeviceDialog(row)}
                    sortModel={filters.sortModel}
                    onSortModelChange={sortModel => setFilters({ ...filters, sortModel })}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 25]} />
            </Box>
            <RenewalDeviceDialog open={renewalDeviceDialog.open} user={renewalDeviceDialog.user} device={renewalDeviceDialog.device} onClose={() => setRenewalDeviceDialog(initialRenewalDeviceProps)} />
            <ViewUserAndDeviceDialog open={viewDeviceDialog.open} user={viewDeviceDialog.user} device={viewDeviceDialog.device} onClose={() => setViewDeviceDialog(initialViewDeviceProps)} onRenewed={handleRenewed} onChangeGsmState={handleOnChangeSmsState} />
        </Card>
    );
}
